import styled, { css } from 'styled-components'
import { desktopBreakpoint, tabletBreakpoint, wideScreenBreakpoint } from 'react-components'
import ExternalLink from '../../common/external-link'

export const HeaderBar = styled.div(
    ({ theme }) => css`
        position: ${theme.style.position.relative};
        display: ${theme.style.display.flex};
        justify-content: ${theme.style.justifyContent.spaceBetween};
        top: 0;
        width: 100%;
        height: 3.25rem;
        z-index: 100;
        background-color: ${theme.colors.white};

        @media only screen and (min-width: ${tabletBreakpoint.min}px) {
            max-width: 87.5rem;
            margin: 0 auto;
        }
        @media only screen and (min-width: ${desktopBreakpoint.min}px) {
            height: 5rem;
        }
    `,
)

export const HeaderBarContent = styled.div(
    ({ theme }) => css`
        width: inherit;
        height: inherit;
        display: ${theme.style.display.flex};
        color: ${theme.colors.tfwBlack87};
        align-items: ${theme.style.alignItems.center};
        justify-content: ${theme.style.justifyContent.spaceBetween};
        font-family: ${theme.typography.font.family.latoVerdana};
        font-size: ${theme.typography.font.size.large};
        font-weight: ${theme.typography.font.weight.normal};
        @media only screen and (min-width: ${desktopBreakpoint.min}px) {
            padding: 0 ${theme.spacing.large};
        }
    `,
)

export const NavigationItem = styled(ExternalLink)(
    ({ theme }) => css`
        display: flex;
        align-items: center;
        font-family: ${theme.typography.font.family.lato};
        font-size: ${theme.typography.font.size.small};
        line-height: 1.5rem;
        color: #212121;
        padding: ${theme.spacing.xxSmall};
        font-weight: ${theme.typography.font.weight.normal};
        @media (min-width: ${tabletBreakpoint.min}px) {
            display: ${theme.style.display.flex};
            padding: 1.25rem ${theme.spacing.small};
            font-size: 0.82rem;
            line-height: normal;
            text-decoration: none;
            color: ${theme.colors.tfwBlack87};

            &:hover {
                text-decoration: underline;
            }
        }
        @media (min-width: 340px) {
            padding: ${theme.spacing.xSmall};
        }
        @media (min-width: ${desktopBreakpoint.min}px) {
            padding: 1.25rem calc(${theme.spacing.small} + ${theme.spacing.xxxSmall});
            font-size: ${theme.typography.font.size.small};
        }
        @media (min-width: ${wideScreenBreakpoint.min}px) {
            padding: 1.25rem ${theme.spacing.medium};
        }
    `,
)

export const NavigationButton = styled(NavigationItem)`
    cursor: pointer;
    display: flex;
    align-items: center;
    &:hover {
        text-decoration: none;
    }
`

export const NavigationButtonText = styled.span(
    ({ theme }) => css`
        ${NavigationButton}:hover & {
            text-decoration: underline;
        }
        font-size: ${theme.typography.font.size.medium};
    `,
)

export const GhostButtonContent = styled.div(
    ({ theme }) => css`
        font: 400 ${theme.typography.font.size.medium} ${theme.typography.font.family.latoVerdana};
        @media only screen and (min-width: ${desktopBreakpoint.min}px) {
            padding: 0.3125rem 1.25rem;
        }
        color: inherit;
    `,
)

export const UserMenuContainer = styled.div`
    display: none;

    @media only screen and (min-width: ${wideScreenBreakpoint.min}px) {
        display: block;
    }
`

export const UserMenu = styled.div(
    ({ theme }) => css`
        font-size: ${theme.typography.font.size.medium};
        display: ${theme.style.display.flex};
        align-items: center;
    `,
)

export const UserMenuLowerContent = styled.div(
    ({ theme }) => css`
        font-weight: ${theme.typography.font.lato.weight.bold};
        padding-left: ${theme.spacing.xxSmall};
    `,
)

export const DownArrow = styled.img`
    height: 0.75rem;
    width: 0.75rem;
`

export const PopUpBox = styled.div(
    ({ theme }) => css`
        display: ${theme.style.display.flex};
        flex-direction: ${theme.style.flexDirection.column};
        background-color: ${theme.colors.white};
        position: absolute;
        width: 15rem;
        z-index: 100;
        right: 5%;
        top: 3.5rem;
        border: 0.0625rem solid ${theme.colors.tfwBlack12};
        border-radius: 0.375rem;
        box-shadow: 0 0.0625rem 0.125rem 0 ${theme.colors.tfwBlack45};
        animation: fadeIn 200ms ease-out;

        @media only screen and (min-width: ${desktopBreakpoint.min}px) {
            top: 4.375rem;
            right: 2%;
        }
    `,
)

export const PopUpBoxWrapper = styled(ExternalLink)(
    ({ theme }) => css`
        display: flex;
        width: 100%;
        padding: 0 ${theme.spacing.small};
        align-items: center;
        text-decoration: none;

        &:hover {
            background-color: ${theme.colors.tfwBlack12};
        }
    `,
)

export const PopUpBoxIcon = styled.img``

export const PopUpBoxItem = styled.span(
    ({ theme }) => css`
        padding: 0.625rem;
        line-height: 1.875rem;
        font-size: ${theme.typography.font.size.medium};
        color: ${theme.colors.tfwBlack87};

        &:hover {
            background-color: ${theme.colors.tfwBlack12};
        }
    `,
)

export const LogoWrapper = styled.a(
    ({ theme }) => css`
        display: ${theme.style.display.flex};
        align-items: ${theme.style.alignItems.center};
        text-decoration: none;
        height: 100%;
        color: ${theme.colors.tfwBlack87};
        padding: 0.625rem 0 0.625rem 0.625rem;
        cursor: pointer;
        @media only screen and (min-width: ${desktopBreakpoint.min}px) {
            padding: 0.625rem ${theme.spacing.xLarge} 0.625rem ${theme.spacing.medium};
        }
    `,
)

export const LogoImage = styled.img`
    height: 1.5rem;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        height: 2.063rem;
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        height: 2.5rem;
    }
`

export const IconImage = styled.img`
    height: 1.375rem;
    cursor: pointer;

    @media only screen and (min-width: ${wideScreenBreakpoint.min}px) {
        margin-right: 0.3125rem;
    }
`

export const UserIcon = styled.img`
    height: ${props => props.theme.layout.xSmall};
    margin-right: 0.3125rem;
`

export const RightSubContainer = styled.div(
    ({ theme }) => css`
        display: ${theme.style.display.flex};
        align-items: ${theme.style.alignItems.center};
        height: 100%;
        @media only screen and (min-width: ${tabletBreakpoint.min}px) {
            font-weight: ${theme.typography.font.weight.bold};
        }
    `,
)

export const LeftSubContainer = styled.div(
    ({ theme }) => css`
        display: ${theme.style.display.flex};
        align-items: ${theme.style.alignItems.center};
        height: 100%;
    `,
)

export const ButtonWrapper = styled(LogoWrapper)(
    ({ theme }) => css`
        position: relative;
        padding: ${theme.spacing.xxSmall} !important;

        @media (min-width: 340px) {
            padding: ${theme.spacing.xSmall} !important;
        }

        @media only screen and (min-width: ${desktopBreakpoint.min}px) {
            padding-left: 1.25rem;

            &:hover {
                text-decoration: underline;
            }
        }
    `,
)

export const LoggedInButtonWrapper = styled.div(
    ({ theme }) => css`
        position: ${theme.style.position.relative};
        display: ${theme.style.display.flex};
        align-items: ${theme.style.alignItems.center};
        text-decoration: none;
        height: 100%;
        color: ${theme.colors.tfwBlack87};
        padding: 0.625rem;
        cursor: pointer;

        @media only screen and (min-width: ${desktopBreakpoint.min}px) {
            padding-left: 1.25rem;

            &:hover {
                text-decoration: underline;
            }
        }
    `,
)

export const GhostButtonWrapper = styled.div(
    ({ theme }) => css`
        display: ${theme.style.display.flex};
        align-items: ${theme.style.alignItems.center};
        padding: ${theme.spacing.xxSmall};
        font-size: ${theme.typography.font.size.xSmall};
        font-weight: ${theme.typography.font.lato.weight.regular};

        @media (min-width: 340px) {
            padding: ${theme.spacing.xSmall};
        }

        @media only screen and (min-width: ${desktopBreakpoint.min}px) {
            padding: 0.625rem 0.625rem 0.625rem ${theme.spacing.medium};
        }
    `,
)

export const MenuIconWrapper = styled.div(
    ({ theme }) => css`
        display: ${theme.style.display.flex};
        align-items: ${theme.style.alignItems.center};
        height: 100%;
        text-decoration: none;
        color: ${theme.colors.tfwBlack87};
        cursor: pointer;
        width: 3.25rem;
        justify-content: ${theme.style.justifyContent.center};
        padding: ${theme.spacing.xxSmall} ${theme.spacing.small} ${theme.spacing.xxSmall} ${theme.spacing.xxSmall};
        @media only screen and (min-width: 340px) {
            padding: ${theme.spacing.xSmall} ${theme.spacing.medium} ${theme.spacing.xSmall} ${theme.spacing.xSmall};
        }
    `,
)

export const DrawerLandlordButtonWrapper = styled.div(
    ({ theme }) => css`
        position: absolute;
        bottom: ${theme.spacing.large};
        transform: translate(-50%, -50%);
        left: 50%;
        width: ${theme.layout.fill};
        padding: 0 1.25rem;

        a {
            width: ${theme.layout.fill};
            text-align: center;
            padding: ${theme.spacing.xSmall} ${theme.spacing.medium};
        }
    `,
)

export const ProfileDrawerHeader = styled.div(
    ({ theme }) => css`
        display: flex;
        align-items: center;
        padding: ${theme.spacing.medium} ${theme.spacing.medium} ${theme.spacing.xSmall} ${theme.spacing.medium};
    `,
)

export const GreetingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: ${props => props.theme.spacing.medium};
`

export const GreetingTitle = styled.span`
    line-height: 24px;
    font-size: ${props => props.theme.typography.font.size.medium};
`

export const GreetingSubtitle = styled.span(
    ({ theme }) => css`
        color: ${theme.colors.tfwBlack38};
        font-size: ${theme.typography.font.size.small};
        line-height: 24px;
    `,
)

export const DrawerItem = styled(ExternalLink)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    padding: ${props => props.theme.spacing.medium};
`

export const DrawerItemLeft = styled.span`
    display: flex;
    align-items: center;
`

export const DrawerItemText = styled.span(
    ({ theme }) => css`
        padding-left: ${theme.spacing.medium};
        font-weight: ${theme.typography.font.lato.weight.light};
        font-size: ${theme.typography.font.size.medium};
    `,
)

export const MenuDrawerHeader = styled.div(
    ({ theme }) => css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: ${theme.typography.border.width.xxxThin} ${theme.colors.tfwBlack12}
            ${theme.typography.border.style.solid};
        padding: ${theme.spacing.xxSmall} ${theme.spacing.medium} ${theme.spacing.xxSmall} ${theme.spacing.xSmall};
    `,
)

export const EventPill = styled.span(
    ({ theme }) => css`
        background: ${theme.colors.tfwErrorRed};
        color: ${theme.colors.white};
        margin-left: ${theme.spacing.xSmall};
        border-radius: ${theme.typography.border.radius.small};
        font-size: ${theme.typography.font.size.xSmall};
        padding: ${theme.spacing.xxSmall} ${theme.spacing.small};
        line-height: 19px;
        text-decoration: none;

        @media only screen and (min-width: ${desktopBreakpoint.min}px) {
            line-height: 16px;
            margin-left: ${theme.spacing.xxSmall};
            padding: ${theme.spacing.xxSmall} ${theme.spacing.small};
        }
    `,
)

interface WishlistIconContainerProps {
    hasCount: boolean
}

export const WishlistIconContainer = styled.div<WishlistIconContainerProps>(
    ({ hasCount }) => css`
        display: flex;
        align-items: ${hasCount ? 'flex-end' : 'center'};
        position: relative;
    `,
)

export const WishlistCount = styled.span(
    ({ theme }) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        height: ${theme.layout.xxSmall};
        width: ${theme.layout.xxSmall};
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 100%;
        font-size: ${theme.typography.font.size.xxxSmall};
        background-color: ${theme.colors.tfwRed};
        border: ${theme.typography.border.width.xxThin} solid ${theme.colors.white};
        color: ${theme.colors.white};
    `,
)

export const WishlistText = styled.span(
    ({ theme }) => css`
        display: none;
        padding-left: ${theme.spacing.xxSmall};

        @media only screen and (min-width: ${wideScreenBreakpoint.min}px) {
            display: block;
        }
        font-size: ${theme.typography.font.size.medium};
    `,
)

export const LoginButtonText = styled.span`
    font-size: ${props => props.theme.typography.font.size.medium};
    display: none;

    @media only screen and (min-width: ${wideScreenBreakpoint.min}px) {
        display: block;
    }
`
