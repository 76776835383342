import React, { Fragment, memo, useContext } from 'react'
import { AnyStyledComponent } from 'styled-components'
import { Header, ScreenContext } from 'react-components'

import {
    ButtonWrapper,
    DownArrow,
    GhostButtonContent,
    HeaderBar,
    HeaderBarContent,
    IconImage,
    LeftSubContainer,
    LoggedInButtonWrapper,
    LogoImage,
    LogoWrapper,
    MenuIconWrapper,
    NavigationButton,
    NavigationItem,
    PopUpBox,
    PopUpBoxIcon,
    PopUpBoxItem,
    PopUpBoxWrapper,
    RightSubContainer,
    UserIcon,
    UserMenu,
    UserMenuLowerContent,
    GhostButtonWrapper,
    EventPill,
    NavigationButtonText,
    UserMenuContainer,
    WishlistText,
    LoginButtonText,
    WishlistIconContainer,
    WishlistCount,
} from './header-section-styles'
import { HeaderSectionTransport } from '../../../transports/region-landing-page-transport'
import ExternalLink from '../../common/external-link'
import { useTranslation } from '../../../hooks/locale'
import GhostButton from '../../common/ghost-button'
import MenuDrawer from './menu-drawer'
import ProfileDrawer from './profile-drawer'
import { useLandingPageHeader } from '../hooks'

import HeartWishlistIcon from '../../../assets/icons/heart-wishlist.svg'
import UserCheckIcon from '../../../assets/icons/userbar-icons/user-check.svg'
import ChevronDownIcon from '../../../assets/icons/chevron-down.svg'
import BookingIcon from '../../../assets/icons/userbar-icons/bookings.svg'
import PersonalDetailsIcon from '../../../assets/icons/userbar-icons/personal-details.svg'
import LogoutIcon from '../../../assets/icons/userbar-icons/log-out.svg'
import NavigationIcon from '../../../assets/icons/userbar-icons/navigation.svg'
import UserHeaderIcon from '../../../assets/icons/userbar-icons/user.svg'
import LandlordAreaIcon from '../../../assets/icons/userbar-icons/usermenu-icons/layout.svg'
import LandlordListingsIcon from '../../../assets/icons/userbar-icons/usermenu-icons/megaphone.svg'
import LandlordInvoicesIcon from '../../../assets/icons/userbar-icons/usermenu-icons/file-text.svg'

interface Props {
    isLoggedIn: boolean
    firstName?: string
    url: string
    headerSection: HeaderSectionTransport
    sitePathname?: string
    showVacationIdeasNavButton: boolean
}

const HeaderSection: React.FC<Props> = ({
    isLoggedIn,
    firstName,
    url,
    headerSection,
    sitePathname = '',
    showVacationIdeasNavButton = true,
}) => {
    const { isDesktop, isMobile, isTablet } = useContext(ScreenContext)
    const { t } = useTranslation()

    const {
        logoutButton,
        guestProfileButton,
        landlordProfileButton,
        wishlistButton,
        userBookingsButton,
        guestInquiriesButton,
        landlordInquiriesButton,
        landlordListingsButton,
        landlordInvoicesButton,
        landlordAreaButton,
        logo,
        loginButton,
        vacationIdeasButton,
        offersButton,
        buyPropertyButton,
        helpButton,
        activitiesButton,
        listRentalButton,
    } = headerSection.navigationSectionResponse.navigation

    const {
        isSidebarOpen,
        toggleSidebar,
        isUserSidebarOpen,
        toggleUserSidebar,
        popUpBoxVisible,
        togglePopUpBoxVisibility,
        listRentalClickHandler,
        wishlistClickHandler,
        wishlistCount,
        isLandlordRole,
        isGuestRole,
        inquiryCount,
    } = useLandingPageHeader({ url, listRentalButton })

    return (
        <Header id="header">
            <HeaderBar>
                <HeaderBarContent>
                    <LeftSubContainer>
                        {!!logo?.image?.url && (
                            <LogoWrapper href={logo.url}>
                                <LogoImage src={logo.image ? logo.image.url : ''} alt={'Logo'} />
                            </LogoWrapper>
                        )}
                        {!isMobile && !isTablet && (
                            <Fragment>
                                {!!vacationIdeasButton?.url && showVacationIdeasNavButton && (
                                    <NavigationButton
                                        href={vacationIdeasButton.url}
                                        noCrawl={vacationIdeasButton.noCrawl}
                                    >
                                        <NavigationButtonText>{vacationIdeasButton.text}</NavigationButtonText>
                                    </NavigationButton>
                                )}
                                {!!offersButton?.url && (
                                    <NavigationButton href={offersButton.url} noCrawl={offersButton.noCrawl}>
                                        <NavigationButtonText>{offersButton.text}</NavigationButtonText>
                                    </NavigationButton>
                                )}
                                {!!buyPropertyButton?.url && (
                                    <NavigationButton href={buyPropertyButton.url} noCrawl={buyPropertyButton.noCrawl}>
                                        <NavigationButtonText>{buyPropertyButton.text}</NavigationButtonText>
                                    </NavigationButton>
                                )}
                                {!!activitiesButton?.url && (
                                    <NavigationButton href={activitiesButton.url} noCrawl={activitiesButton.noCrawl}>
                                        <NavigationButtonText>{activitiesButton.text}</NavigationButtonText>
                                        <EventPill>{t('new')}</EventPill>
                                    </NavigationButton>
                                )}
                                {!!helpButton?.url && (
                                    <NavigationButton href={helpButton.url} noCrawl={helpButton.noCrawl}>
                                        <NavigationButtonText>{helpButton.text}</NavigationButtonText>
                                    </NavigationButton>
                                )}
                            </Fragment>
                        )}
                    </LeftSubContainer>
                    <RightSubContainer>
                        {(isMobile || isTablet) && !isLoggedIn && !!listRentalButton?.url && (
                            <GhostButtonWrapper>
                                <GhostButton onClick={listRentalClickHandler} padding={'0.3125rem'} hoverEffect>
                                    <GhostButtonContent>{listRentalButton.text}</GhostButtonContent>
                                </GhostButton>
                            </GhostButtonWrapper>
                        )}
                        {!!wishlistButton?.url && (
                            <NavigationItem
                                href={wishlistButton.url}
                                onClick={wishlistClickHandler}
                                key={wishlistButton.text}
                                noCrawl={wishlistButton.noCrawl}
                            >
                                <WishlistIconContainer hasCount={!!wishlistCount}>
                                    <IconImage as={HeartWishlistIcon as AnyStyledComponent} />
                                    {wishlistCount && <WishlistCount>{wishlistCount}</WishlistCount>}
                                </WishlistIconContainer>
                                <WishlistText>{wishlistButton.text}</WishlistText>
                            </NavigationItem>
                        )}
                        {isLoggedIn ? (
                            <Fragment>
                                {(isMobile || isTablet) && (
                                    <ButtonWrapper onClick={toggleUserSidebar}>
                                        <IconImage as={UserCheckIcon as AnyStyledComponent} />
                                    </ButtonWrapper>
                                )}
                                {!isMobile && !isTablet && (
                                    <LoggedInButtonWrapper
                                        onMouseEnter={() => togglePopUpBoxVisibility(true)}
                                        onMouseLeave={() => togglePopUpBoxVisibility(false)}
                                    >
                                        <UserIcon as={UserHeaderIcon as AnyStyledComponent} />

                                        <UserMenuContainer>
                                            <UserMenu>
                                                {firstName}
                                                <UserMenuLowerContent>
                                                    <DownArrow as={ChevronDownIcon as AnyStyledComponent} />
                                                </UserMenuLowerContent>
                                            </UserMenu>
                                        </UserMenuContainer>

                                        {popUpBoxVisible && (
                                            <PopUpBox>
                                                {isLandlordRole && (
                                                    <PopUpBoxWrapper
                                                        href={landlordAreaButton.url}
                                                        noCrawl={landlordAreaButton.noCrawl}
                                                    >
                                                        <PopUpBoxIcon as={LandlordAreaIcon as AnyStyledComponent} />
                                                        <PopUpBoxItem>{landlordAreaButton.text}</PopUpBoxItem>
                                                    </PopUpBoxWrapper>
                                                )}
                                                {isGuestRole && (
                                                    <PopUpBoxWrapper
                                                        href={guestInquiriesButton.url}
                                                        noCrawl={guestInquiriesButton.noCrawl}
                                                    >
                                                        <PopUpBoxIcon as={BookingIcon as AnyStyledComponent} />
                                                        <PopUpBoxItem>
                                                            {guestInquiriesButton.text}
                                                            {inquiryCount ? ` [${inquiryCount}]` : ''}
                                                        </PopUpBoxItem>
                                                    </PopUpBoxWrapper>
                                                )}
                                                {isLandlordRole && (
                                                    <PopUpBoxWrapper
                                                        href={landlordInquiriesButton.url}
                                                        noCrawl={landlordInquiriesButton.noCrawl}
                                                    >
                                                        <PopUpBoxIcon as={BookingIcon as AnyStyledComponent} />
                                                        <PopUpBoxItem>
                                                            {landlordInquiriesButton.text}
                                                            {inquiryCount ? ` [${inquiryCount}]` : ''}
                                                        </PopUpBoxItem>
                                                    </PopUpBoxWrapper>
                                                )}
                                                <PopUpBoxWrapper
                                                    href={userBookingsButton.url}
                                                    noCrawl={userBookingsButton.noCrawl}
                                                >
                                                    <PopUpBoxIcon as={BookingIcon as AnyStyledComponent} />
                                                    <PopUpBoxItem>{userBookingsButton.text}</PopUpBoxItem>
                                                </PopUpBoxWrapper>
                                                {isLandlordRole && (
                                                    <PopUpBoxWrapper
                                                        href={landlordListingsButton.url}
                                                        noCrawl={landlordListingsButton.noCrawl}
                                                    >
                                                        <PopUpBoxIcon as={LandlordListingsIcon as AnyStyledComponent} />
                                                        <PopUpBoxItem>{landlordListingsButton.text}</PopUpBoxItem>
                                                    </PopUpBoxWrapper>
                                                )}
                                                {isLandlordRole && (
                                                    <PopUpBoxWrapper
                                                        href={landlordInvoicesButton.url}
                                                        noCrawl={landlordInvoicesButton.noCrawl}
                                                    >
                                                        <PopUpBoxIcon as={LandlordInvoicesIcon as AnyStyledComponent} />
                                                        <PopUpBoxItem>{landlordInvoicesButton.text}</PopUpBoxItem>
                                                    </PopUpBoxWrapper>
                                                )}
                                                {isGuestRole && (
                                                    <PopUpBoxWrapper
                                                        href={guestProfileButton.url}
                                                        noCrawl={guestProfileButton.noCrawl}
                                                    >
                                                        <PopUpBoxIcon as={PersonalDetailsIcon as AnyStyledComponent} />
                                                        <PopUpBoxItem>{guestProfileButton.text}</PopUpBoxItem>
                                                    </PopUpBoxWrapper>
                                                )}
                                                {isLandlordRole && (
                                                    <PopUpBoxWrapper
                                                        href={landlordProfileButton.url}
                                                        noCrawl={landlordProfileButton.noCrawl}
                                                    >
                                                        <PopUpBoxIcon as={PersonalDetailsIcon as AnyStyledComponent} />
                                                        <PopUpBoxItem>{landlordProfileButton.text}</PopUpBoxItem>
                                                    </PopUpBoxWrapper>
                                                )}
                                                <PopUpBoxWrapper href={logoutButton.url} noCrawl={logoutButton.noCrawl}>
                                                    <PopUpBoxIcon as={LogoutIcon as AnyStyledComponent} />
                                                    <PopUpBoxItem>{logoutButton.text}</PopUpBoxItem>
                                                </PopUpBoxWrapper>
                                            </PopUpBox>
                                        )}
                                    </LoggedInButtonWrapper>
                                )}
                            </Fragment>
                        ) : (
                            <>
                                {!isMobile && !isTablet ? (
                                    <ExternalLink
                                        href={`${loginButton.url}?target=${sitePathname}`}
                                        as={NavigationItem}
                                        noCrawl
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <IconImage as={UserHeaderIcon as AnyStyledComponent} />
                                        <LoginButtonText>{loginButton.text}</LoginButtonText>
                                    </ExternalLink>
                                ) : (
                                    <ExternalLink
                                        href={`${loginButton.url}?target=${sitePathname}`}
                                        as={ButtonWrapper}
                                        noCrawl
                                    >
                                        <IconImage as={UserHeaderIcon as AnyStyledComponent} />
                                    </ExternalLink>
                                )}
                            </>
                        )}
                        {(isMobile || isTablet) && (
                            <MenuIconWrapper onClick={toggleSidebar}>
                                <IconImage as={NavigationIcon as AnyStyledComponent} />
                            </MenuIconWrapper>
                        )}
                        {!isMobile && !isTablet && !!listRentalButton?.url && (isLoggedIn ? !isLandlordRole : true) && (
                            <GhostButtonWrapper>
                                <GhostButton onClick={listRentalClickHandler} padding={'0.3125rem'} hoverEffect>
                                    <GhostButtonContent>{listRentalButton.text}</GhostButtonContent>
                                </GhostButton>
                            </GhostButtonWrapper>
                        )}
                    </RightSubContainer>
                </HeaderBarContent>
            </HeaderBar>
            {(isMobile || isTablet) && (
                <MenuDrawer
                    isLoggedIn={isLoggedIn}
                    open={isSidebarOpen && !isDesktop}
                    onClose={toggleSidebar}
                    headerSection={headerSection}
                    listRentalClickHandler={listRentalClickHandler}
                    showVacationIdeasNavButton={showVacationIdeasNavButton}
                    isLandlordRole={isLandlordRole}
                    isGuestRole={isGuestRole}
                />
            )}
            {(isMobile || isTablet) && isLoggedIn && (
                <ProfileDrawer
                    open={isUserSidebarOpen && !isDesktop}
                    onClose={toggleUserSidebar}
                    headerSection={headerSection}
                    isLandlordRole={isLandlordRole}
                    isGuestRole={isGuestRole}
                    firstName={firstName}
                    inquiryCount={inquiryCount}
                />
            )}
        </Header>
    )
}

export default memo(HeaderSection)
